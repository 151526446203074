import React from 'react';
import Logo from '../images/greenroom-logo_50x50.png';

export default function Header(props: any) {
	return (
		<header>
			<button className="hamburger-btn" onClick={props.mobileMenuToggle}>&#9776;</button>
			<div className="header-left">
				<img src={Logo} alt="Greenroom" />
				<h1>Greenroom Super Admin</h1>
			</div>
			{props.isSignedIn && <button className="xsmall" onClick={props.signOut}>Sign Out</button>}
		</header>
	)
}