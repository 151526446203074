/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";
import './styles.scss';
import * as UserActions from './stores/actions/user';
import * as ChannelsActions from './stores/actions/channels';
import { UserState } from './stores/reducers/user';
import Login from './components/login';
import Header from './components/header';
import UsersList from './components/users-list';
import Channels from './components/channels';
import Alert from './components/alert';
import CreateEditChannel from './components/create-edit-channel';
import * as Errors from './utils/error-handler';
import AddEditProducer from './components/add-edit-producer';

interface OwnProps {
}

interface DispatchProps {
	checkUser: Function;
	loadChannels: Function;
	signOut: Function;
}

interface StateProps {
	UserReducer: UserState;
}

const mapStateToProps = (state: any, ownProps: any) => {
	return { ...state, ...ownProps }
}

const mapDispatchToProps = (dispatch: Function) => {
	return {
		checkUser: () => {
			dispatch(UserActions.checkUser());
		},
		loadChannels: (token: string) => {
			dispatch(ChannelsActions.loadChannels(token));
		},
		signOut: () => {
			dispatch(UserActions.signOut())
		}
	}
}

type Props = StateProps & DispatchProps & OwnProps;

function App(props: Props) {
	const {
		checkUser,
		loadChannels,
		signOut,
		UserReducer: {
			token,
			user
		}
	} = props;

	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const activityTime = useRef(Date.now() + (1000 * 60 * 60));

	function handleActivity() {
		activityTime.current = Date.now() + (1000 * 60 * 60);
	}

	useEffect(() => {
		checkUser();
		Errors.setupErrorHandling();
		window.addEventListener('mousedown', handleActivity);
		window.addEventListener('scroll', handleActivity);
		window.addEventListener('keydown', handleActivity);

		setInterval(() => {
			if (activityTime.current < Date.now()) {
				signOut();
			}
		}, 10000);
	}, []);

	useEffect(() => {
		if (token) {
			loadChannels(token);
			Errors.setErrorToken(token);
		}
	}, [token]);

	function handleSignOut() {
		signOut();
		window.location.reload();
	}

	function handleMobileMenuToggle() {
		console.log('here');
		setMobileMenuOpen(!mobileMenuOpen);
	}

	return (
		<div className="App">
			<Header signOut={handleSignOut} isSignedIn={!!user} mobileMenuToggle={handleMobileMenuToggle} />
			{!!user ? (
				<Router>
					<div className="wrapper">
						<nav className="desktop">
							<ul>
								<li>
									<Link to="/producers">Producers</Link>
								</li>
								<li>
									<Link to="/presenters">Presenters</Link>
								</li>
								<li>
									<Link to="/channels">Channels</Link>
								</li>
							</ul>
						</nav>

						{mobileMenuOpen && <nav className="mobile">
							<button className="close-btn" onClick={handleMobileMenuToggle}>&#10005;</button>
							<ul>
								<li>
									<Link to="/producers" onClick={handleMobileMenuToggle}>Producers</Link>
								</li>
								<li>
									<Link to="/channels" onClick={handleMobileMenuToggle}>Channels</Link>
								</li>
							</ul>
						</nav>}

						<Switch>
							<Route path="/producers/:brandlive_user">
								<AddEditProducer isNewProducer={false} />
							</Route>
							<Route path="/producers">
								<UsersList isProducers={true} />
							</Route>
							<Route path="/add-producer">
								<AddEditProducer isNewProducer={true} />
							</Route>
							<Route path="/presenters">
								<UsersList isProducers={false} />
							</Route>
							<Route path="/create-channel">
								<CreateEditChannel isNewChannel={true} />
							</Route>
							<Route path="/channels/:client">
								<CreateEditChannel isNewChannel={false} />
							</Route>
							<Route path="/channels">
								<Channels />
							</Route>
							<Route path="/">
								<UsersList isProducers={true} />
							</Route>
						</Switch>
					</div>
				</Router>
			) : (
				<Login />
			)}
			<Alert />
		</div>
	);
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps)(App);