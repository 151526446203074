import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateNewChannel, UpdateChannel } from '../connection/channels';
import { useHistory } from 'react-router-dom';
import { GetUploadEndpoint, MakeItemPublic, UploadToS3 } from '../connection/upload';
import { Channel, GreenroomAdminState } from '../types/types';
import * as ChannelActions from '../stores/actions/channels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

interface CreateChannelForm extends HTMLFormElement {
	channel_name: HTMLInputElement;
}

interface Props {
	isNewChannel: boolean
}

export default function CreateEditChannel(props: Props) {
	const {
		UserReducer: {
			token
		},
		ChannelsReducer: {
			selectedChannel
		}
	} = useSelector((state: GreenroomAdminState) => state);

	const { isNewChannel } = props;

	const [savingChannel, setSavingChannel] = useState(false);
	const logoWhiteFileInput = useRef<HTMLInputElement | null>(null);
	const logoWhiteFile = useRef<File | null>(null);
	const [error, setError] = useState("");
	const history = useHistory();

	async function uploadLogo(channel_id: string | number) {
		const filename = logoWhiteFile!.current!.name;
		const prefix = `uploads/greenroom-channels/${channel_id}`;
		const endpoint = await GetUploadEndpoint(token!, prefix, logoWhiteFile!.current!.name);
		await UploadToS3(endpoint.url, logoWhiteFile.current!, endpoint.fields, undefined, filename);
		await MakeItemPublic(token!, endpoint.fields.Key);
		return `${endpoint.url}/${prefix}/${filename}`;
	}

	async function saveChannel(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setError("");

		const target = e.target as CreateChannelForm;
		const {
			channel_name
		} = target;

		if (!channel_name.value) {
			setError("You must enter a channel name");
			return;
		}

		setSavingChannel(true);

		if (isNewChannel) {
			const result = await CreateNewChannel(token, channel_name.value).catch(e => {
				setError(e.toString());
			});
			const channel_id = result.channel_id;

			// Update channel with logo if selected
			let logoWhiteURL;
			if (logoWhiteFile!.current) {
				logoWhiteURL = await uploadLogo(channel_id)

				const channel = {
					channel: channel_id,
					name: channel_name.value,
					logo_white: logoWhiteURL ? logoWhiteURL : null
				} as Channel;
				await UpdateChannel(token, channel).catch(e => {
					setError(e.toString());
					return;
				});
			}

			// Navigate back to channels list (refresh)
			window.location.href = window.location.origin + "/channels"
		} else {
			// Editing existing channel
			const updatedChannel = { ...selectedChannel } as Channel;
			updatedChannel.name = channel_name.value;

			let logoWhiteURL;
			if (logoWhiteFile!.current) {
				logoWhiteURL = await uploadLogo(selectedChannel!.channel)
				updatedChannel.logo_white = logoWhiteURL;
			}

			await UpdateChannel(token, updatedChannel!).catch(e => {
				setError(e.toString());
				return;
			});

			// Navigate back to channels list (refresh)
			window.location.href = window.location.origin + "/channels"
		}
	}

	function uploadWhiteLogo(e: React.MouseEvent<HTMLButtonElement>) {
		e.preventDefault();
		logoWhiteFileInput.current?.click();
	}

	function handleUploadWhiteLogo(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.target.files) {
			logoWhiteFile.current = event.target.files[0];
			const whiteLogoImg = document.getElementById('logo-white-img') as HTMLImageElement;
			whiteLogoImg!.src = URL.createObjectURL(event.target.files[0]);
			whiteLogoImg.onload = function() {
				URL.revokeObjectURL(whiteLogoImg.src) // free memory
			}
		}
	}

	return (
		<div className="page-wrapper">
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
				<button style={{ background: 'none', padding: 6, margin: 0, marginRight: 6 }} onClick={history.goBack}><FontAwesomeIcon icon={faArrowLeft} /></button>
				<h1 style={{ margin: 0 }}>{isNewChannel ? "Create" : "Edit"} Channel</h1>
			</div>
			<form className='create-channel' onSubmit={saveChannel}>
				<div className="field-group">
					<label>Channel Name</label>
					<input type="text" placeholder="Enter channel name" name="channel_name" defaultValue={isNewChannel ? "" : (selectedChannel ? selectedChannel!.name : "")} />
				</div>
				<div className="image-container" style={{ color: '#fff' }}>
					<div className="image-actions">
						<label style={{ color: 'white' }}>White Logo</label>
						<button onClick={uploadWhiteLogo} className="button action">Select Image</button>
					</div>

					<input type="file" ref={logoWhiteFileInput} style={{ display: 'none' }} onChange={handleUploadWhiteLogo} />

					<div className="logo-container">
						<img id="logo-white-img" src={selectedChannel?.logo_white} />
					</div>
				</div>

				<input type="submit" disabled={savingChannel} className="button action" value={savingChannel ? "Please wait..." : (isNewChannel ? "Submit" : "Save")} />
			</form>
			{ !!error && <span className="error">{error}</span>}
		</div >
	)
}