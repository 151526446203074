import { Channel } from "../types/types";

const headers = {
	Accept: "application/json",
	"Content-Type": "application/json"
}

function getHeaders(token: string) {
	return {
		...headers,
		'bl-token': token
	}
}

export const LoadChannels = (token: string) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/channels`, {
		method: 'GET',
		headers: getHeaders(token)
	}).then(result => {
		if (result.ok) {
			let json = result.json();
			return json;
		} else {
			throw new Error(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/channels`);
		}
	});
}

export const UpdateChannel = (token: string, channel: Channel) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/channels/update`, {
		method: 'POST',
		headers: getHeaders(token),
		body: JSON.stringify(channel)
	}).then(result => {
		if (result.ok) {
			return result.ok;
		} else {
			throw new Error(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/channels/update`);
		}
	});
}

export const LoadSingleChannel = (token: string, client: number) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/super-admin/channels/${client}`, {
		method: 'GET',
		headers: getHeaders(token)
	}).then(result => {
		if (result.ok) {
			let json = result.json();
			return json;
		} else {
			throw new Error(`${process.env.REACT_APP_API_HOST}/v2/super-admin/channels/${client}`);
		}
	});
}

export const CreateNewChannel = (token: string, name: string) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/channels/create`, {
		method: 'POST',
		headers: getHeaders(token),
		body: JSON.stringify({
			name
		})
	}).then(result => {
		if (result.ok) {
			return result.json();
		} else {
			throw new Error(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/channels/create`);
		}
	});
}