import { Action, AsyncAction, GreenroomUser } from '../../types/types';
import { LoadPresenters, LoadProducers, VerifyProducer } from '../../connection/users';

export const LOAD_PRODUCERS = "LOAD_PRODUCERS";
export function loadProducers(token: string): AsyncAction {
	return {
		type: LOAD_PRODUCERS,
		promise: LoadProducers(token)
	}
}

export const LOAD_PRESENTERS = "LOAD_PRESENTERS";
export function loadPresenters(token: string): AsyncAction {
	return {
		type: LOAD_PRESENTERS,
		promise: LoadPresenters(token)
	}
}

export const CLEAR_USERS = "CLEAR_USERS";
export function clearUsers(): Action {
	return {
		type: CLEAR_USERS,
		payload: null
	}
}

export const SET_SELECTED_USER = "SET_SELECTED_USER";
export function setSelectedUser(user: GreenroomUser): Action {
	return {
		type: SET_SELECTED_USER,
		payload: user
	}
}

export const UPDATE_SELECTED_USER = "UPDATE_SELECTED_USER";
export function updateSelectedUser(user: GreenroomUser): Action {
	return {
		type: UPDATE_SELECTED_USER,
		payload: user
	}
}

export const SET_VERIFIED = "SET_VERIFIED";
export function setVerified(token: string, gr_user: number): AsyncAction {
	return {
		type: SET_VERIFIED,
		promise: VerifyProducer(token, gr_user)
	}
}