export const ReportError = (token: string, user_agent: string, stack: any, message: any) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/error-reporting/super-admin`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			token: token,
			user_agent: user_agent,
			stack: stack,
			message: message
		})
	})
		.then(res => {
			if (res.ok) {
				return true;
			} else {
				return false;
			}
		})
		.catch(e => {
			console.error(e);
			return false;
		});
};
