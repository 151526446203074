import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ReactList from 'react-list';
import { Channel, GreenroomAdminState } from '../types/types';
import * as ChannelActions from '../stores/actions/channels';

export default function Channels() {
	const {
		ChannelsReducer: {
			channels
		},
	} = useSelector((state: GreenroomAdminState) => state);

	const [visibleChannels, setVisibleChannels] = useState(channels);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		setVisibleChannels(channels);
		dispatch(ChannelActions.setSelectedChannel(null));
	}, [channels]);

	function ChannelRow(index: number, key: number | string): JSX.Element {
		const channel = visibleChannels[index];
		return (
			<div className="user-row" key={key}>
				<span className="channel">{channel.name}</span>
				<span className="actions">
					<Link onClick={() => dispatch(ChannelActions.setSelectedChannel(channel))} to={"/channels/" + channel.channel}>Edit</Link>
				</span>
			</div>
		)
	}

	function search(e: React.ChangeEvent<HTMLInputElement>) {
		let visChannels = channels
			.filter((channel: Channel) => channel.name.toLowerCase().includes(e.target.value.toLowerCase().trim()))
		setVisibleChannels(visChannels);
	}

	return (
		<div className="page-wrapper channels">
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<h1>Channels</h1>

				<div style={{ display: 'flex', height: 30 }}>
					<button className="xsmall round action" style={{ marginRight: 6 }} onClick={() => history.push('/create-channel')}>+ Create Channel</button>
				</div>
			</div>

			<input type="text" placeholder="Search for Channel Name" onInput={search} autoFocus={true} />
			<div className="user-row header">
				<span className="channel">Name</span>
				<span className="actions">
					Actions
				</span>
			</div>
			<ReactList
				itemRenderer={ChannelRow}
				useTranslate3d={true}
				length={visibleChannels.length}
				type="uniform"
				threshold={600}
			/>
		</div>
	)
}