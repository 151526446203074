import { createStore, applyMiddleware } from 'redux';
import { middleware as reduxPackMiddleware } from 'redux-pack';
import thunk from 'redux-thunk';
import appReducers from './reducers/main';
import { composeWithDevTools } from 'redux-devtools-extension';

const store = createStore(
	appReducers,
	composeWithDevTools(applyMiddleware(thunk, reduxPackMiddleware))
);

export default store;