const LOGIN_API_HOST = process.env.REACT_APP_API_HOST;

export const authLogin = (email: string, password: string) => {
	//add a trailing slash to make this work
	return fetch(LOGIN_API_HOST + "/v2/super-admin/sign-in", {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json"
		},
		body: JSON.stringify({
			email: email,
			password: password
		})
	})
		.then(res => {
			try {
				//note -- res.json() can only be called ONCE or it will trigger promise rejection
				var response = res.json();
				return response;
			} catch (e) {
				console.log(e);
				return { message: "Connection Error" };
			}
		})
		.catch(e => {
			console.log(e);
		});
};