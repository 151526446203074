import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Channel, GreenroomAdminState, GreenroomUser } from '../types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { AddProducer, UpdateProducer } from '../connection/users';

interface CreateChannelForm extends HTMLFormElement {
	first_name: HTMLInputElement;
	last_name: HTMLInputElement;
	email: HTMLInputElement;
}

interface Props {
	isNewProducer: boolean
}

export default function AddEditProducer(props: Props) {
	const {
		UserReducer: {
			token
		},
		ChannelsReducer: {
			channels,
			channelsMap
		},
		UsersReducer: {
			selectedUser
		}
	} = useSelector((state: GreenroomAdminState) => state);

	const { isNewProducer } = props;

	const [addedChannels, setAddedChannels] = useState([] as number[]);
	const [savingProducer, setSavingProducer] = useState(false);
	const [error, setError] = useState("");
	const selectChannel = useRef<HTMLSelectElement | null>(null);
	const history = useHistory();

	useEffect(() => {
		// Channels stored as comma separated string in MySQL, convert to number array for local use
		if (!isNewProducer && selectedUser?.gr_channels) {
			const addedChannelsArray = selectedUser.gr_channels.split(",").map(str => Number(str));
			setAddedChannels(addedChannelsArray!);
		}
	}, [selectedUser])

	async function addEditProducer(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setError("");

		const target = e.target as CreateChannelForm;
		const {
			first_name,
			last_name,
			email
		} = target;

		if (!email.value) {
			setError("You must enter an email address");
			return;
		}

		setSavingProducer(true);

		const producer = {
			first_name: first_name.value,
			last_name: last_name.value,
			email: email.value,
			gr_channels: addedChannels.join(",")
		} as GreenroomUser;

		let error = false;
		if (isNewProducer) {
			await AddProducer(token, producer).catch(e => {
				error = true;
				setError(e.toString());
			});
		} else {
			producer.gr_user = selectedUser?.gr_user;
			await UpdateProducer(token, producer).catch(e => {
				setError(e.toString());
			});
		}

		setSavingProducer(false);

		// Navigate back to producers list (refresh)
		if (!error) {
			window.location.href = window.location.origin + "/producers"
		}
	}

	function addChannel() {
		if (!isNaN(Number(selectChannel.current?.value))) {
			setAddedChannels([...addedChannels, Number(selectChannel.current?.value)]);
		}
	}

	function removeChannel(client: number) {
		const updatedChannels = addedChannels.filter(channel => channel !== client)
		setAddedChannels(updatedChannels);
	}

	return (
		<div className="page-wrapper">
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
				<button style={{ background: 'none', padding: 6, margin: 0, marginRight: 6 }} onClick={history.goBack}><FontAwesomeIcon icon={faArrowLeft} /></button>
				<h1 style={{ margin: 0 }}>{isNewProducer ? 'Add' : 'Edit'} Producer</h1>
			</div>
			<form className='user-form' onSubmit={addEditProducer}>
				<div className="field-group">
					<label>First Name</label>
					<input type="text" placeholder="Enter first name" name="first_name" defaultValue={isNewProducer ? '' : (selectedUser?.first_name ? selectedUser!.first_name : '')} />
				</div>
				<div className="field-group">
					<label>Last Name</label>
					<input type="text" placeholder="Enter first name" name="last_name" defaultValue={isNewProducer ? '' : (selectedUser?.last_name ? selectedUser!.last_name : '')} />
				</div>
				<div className="field-group">
					<label>Email</label>
					<input type="text" placeholder="Enter email" name="email" defaultValue={isNewProducer ? "" : selectedUser?.email} />
				</div>
				<div className="field-group">
					<label>Add Channels</label>
					<div className="select-form">
						<select ref={selectChannel}>
							<option disabled selected>Select Channel</option>
							{channels.map((channel: Channel) => {
								return !addedChannels.includes(channel.channel) ? (
									<option key={channel.channel} value={channel.channel}>{channel.name}</option>
								) : null;
							})}
						</select>
						<button className="small action" onClick={(e) => {
							e.preventDefault();
							addChannel()
						}}>Add</button>
					</div>
				</div>

				{addedChannels && (
					<ul style={{ marginTop: 20 }}>
						<h2>Channels</h2>
						{addedChannels.map((channel: number) => {
							return (
								<li key={channel} className='darken-backdrop-on-hover'>{channelsMap.get(channel)?.name} <button className="xsmall delete" onClick={() => removeChannel(channel)}>Remove</button></li>
							)
						})}
						{addedChannels.length === 0 &&
							<span>None added yet</span>
						}
					</ul>
				)}

				<input type="submit" disabled={savingProducer} className="button action" value={savingProducer ? "Please wait..." : (isNewProducer ? "Submit" : "Save")} />
			</form>
			{ !!error && <span className="error">{error}</span>}
		</div >
	)
}