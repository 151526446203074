/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactList from 'react-list';
import { GreenroomAdminState, GreenroomUser } from '../types/types';
import * as UsersActions from '../stores/actions/users';
import classNames from 'classnames';

interface Props {
	isProducers: boolean
}

export default function UsersList(props: Props) {
	const {
		UsersReducer: {
			users,
			usersError
		},
		UserReducer: {
			token
		},
		ChannelsReducer: {
			channelsMap
		},
	} = useSelector((state: GreenroomAdminState) => state);

	const { isProducers } = props;

	const dispatch = useDispatch();
	const history = useHistory();

	const [visibleUsers, setVisibleUsers] = useState(users);

	useEffect(() => {
		dispatch(UsersActions.clearUsers());
		if (isProducers) {
			dispatch(UsersActions.loadProducers(token));
		} else {
			dispatch(UsersActions.loadPresenters(token));
		}
	}, [isProducers]);

	useEffect(() => {
		setVisibleUsers(users);
	}, [users]);

	function selectUser(user: GreenroomUser) {
		dispatch(UsersActions.setSelectedUser(user));
	}

	function verifyUser(user: GreenroomUser) {
		dispatch(UsersActions.setVerified(token, user.gr_user!))
	}

	function VerificationRow(user: GreenroomUser) {
		if (user.verified) {
			return <span>Verified</span>;
		} else {
			return <span>Awaiting Verification <button className="xsmall action" style={{ marginTop: 5 }} onClick={() => verifyUser(user)}>Verify Now</button></span>
		}
	}

	function channelToDisplay(user: GreenroomUser) {
		if (user.gr_channels) {
			let channelValue = channelsMap.get(parseInt(user.gr_channels!.split(',')[0]))?.name;
			if (user.gr_channels!.split(',').length > 1) {
				channelValue += ` (+${user.gr_channels!.split(',').length - 1})`
			}
			return channelValue;
		}
	}

	function RenderItem(index: number, key: number | string): JSX.Element {
		const user = visibleUsers[index];
		return (
			/* 
				Get original list index even / odd status since variable height 
				list items lead to unknown number of items in DOM which in turn
				leads to unexpected results when using CSS to determine which 
				rows are even or odd.
			*/
			<div className={classNames("user-row", { odd: index % 2 !== 0 })} key={key}>
				{isProducers &&
					<span className="channel">{channelToDisplay(user)}</span>
				}
				<span className="first-name">{user.first_name}</span>
				<span className="last-name">{user.last_name}</span>
				<span className="email">{user.email}</span>
				<span className="profile-status">{VerificationRow(user)}</span>
				{isProducers &&
					<Link onClick={() => selectUser(user)} to={`/producers/${user.gr_user}`} className="actions">Edit</Link>
				}
			</div>
		)
	}

	function search(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.value) {
			let visUsers = users.filter((user: GreenroomUser) => user.email.toLowerCase().includes(e.target.value.toLowerCase().trim()));
			setVisibleUsers(visUsers);
		} else {
			setVisibleUsers(users);
		}
	}

	return (
		<div className={classNames("page-wrapper", isProducers && "producers", !isProducers && "presenters")}>

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<h1>{isProducers ? 'Producers' : 'Presenters'}</h1>

				{isProducers &&
					<div style={{ display: 'flex', height: 30 }}>
						<button className="xsmall round action" style={{ marginRight: 6 }} onClick={() => history.push('/add-producer')}>+ Add Producer</button>
					</div>
				}
			</div>

			{usersError && <span className="error">{usersError}</span>}
			<input type="text" placeholder={"Search for Email"} onChange={search} autoFocus={true} />
			<div className="user-row header">
				{isProducers &&
					<span className="channel">Channel</span>
				}
				<span className="first-name">First Name</span>
				<span className="last-name">Last Name</span>
				<span className="email">Email</span>
				<span className="profile-status">Profile Status</span>
				{isProducers &&
					<span className="actions">Actions</span>
				}
			</div>
			<ReactList
				itemRenderer={RenderItem}
				useTranslate3d={true}
				length={visibleUsers.length}
				type="variable"
			/>
		</div>
	)
}