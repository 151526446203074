interface StoreItem {
	item: string;
	__expiration?: number;
}

export function setStorageItem(key: string, item: string, expirationInHours?: number): void {
	if (typeof item !== 'string') {
		throw new Error("Item in Set Storage must be a string - stringify any objects");
	}

	let storeItem: StoreItem = {
		item: item
	};

	if (expirationInHours) {
		storeItem.__expiration = Date.now() + (expirationInHours * 60 * 60 * 1000);
	}

	localStorage.setItem(key, JSON.stringify(storeItem));
}

export function setStorageObject(key: string, item: any, expirationInHours?: number) {
	if (expirationInHours) {
		item.__expiration = Date.now() + (expirationInHours * 60 * 60 * 1000);
	}

	localStorage.setItem(key, JSON.stringify(item));
}

/**
 * 
 * @param {string} key 
 */
export function getStorageItem(key: string): any | null {
	let str = localStorage.getItem(key);

	if (str) {
		let item = JSON.parse(str);

		if (item.__expiration && item.__expiration < Date.now()) {
			removeStorageItem(key);
			return null;
		} else {
			return item.item;
		}
	} else {
		return null;
	}
}

export function getStorageObject(key: string): any | null {
	let str = localStorage.getItem(key);

	if (str) {
		let item = JSON.parse(str);

		if (item.__expiration && item.__expiration < Date.now()) {
			removeStorageItem(key);
			return null;
		} else {
			return item;
		}
	} else {
		return null;
	}
}

export function removeStorageItem(key: string): void {
	localStorage.removeItem(key);
}

export function clearStorage(): void {
	localStorage.clear();
}