import { Action, AsyncAction } from '../../types/types';
import { authLogin } from '../../connection/auth';

export const CHECK_USER = "CHECK_USER";
export function checkUser(): Action {
	return {
		type: CHECK_USER,
		payload: null
	}
}

export const SIGN_IN = 'SIGN_IN';
export function signIn(email: string, password: string): AsyncAction {
	return {
		type: SIGN_IN,
		promise: authLogin(email, password)
	}
}

export const SIGN_OUT = "SIGN_OUT";
export function signOut(): Action {
	return {
		type: SIGN_OUT,
		payload: null
	}
}