import { GreenroomUser } from "../types/types";

const headers = {
	Accept: "application/json",
	"Content-Type": "application/json"
}

function getHeaders(token: string) {
	return {
		...headers,
		'bl-token': token
	}
}

export const LoadProducers = (token: string) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/producers`, {
		method: 'GET',
		headers: getHeaders(token)
	}).then(result => {
		if (result.ok) {
			let json = result.json();
			return json;
		} else {
			throw new Error(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/producers`);
		}
	});
}

export const LoadPresenters = (token: string) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/presenters`, {
		method: 'GET',
		headers: getHeaders(token)
	}).then(result => {
		if (result.ok) {
			let json = result.json();
			return json;
		} else {
			throw new Error(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/producers`);
		}
	});
}

export const AddProducer = (token: string, producer: GreenroomUser) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/producers/add`, {
		method: 'POST',
		headers: getHeaders(token),
		body: JSON.stringify(producer)
	}).then(async result => {
		if (result.ok) {
			return result.ok;
		} else {
			const message = await result.text();
			throw new Error(message ? message : `process.env.REACT_APP_API_HOST}/v2/greenroom/admin/producers/add`);
		}
	});
}

export const UpdateProducer = (token: string, producer: GreenroomUser) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/producers/update`, {
		method: 'POST',
		headers: getHeaders(token),
		body: JSON.stringify(producer)
	}).then(result => {
		if (result.ok) {
			return result.ok;
		} else {
			throw new Error(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/producers/update`);
		}
	});
}

export const SearchProfile = (token: string, email: string) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/super-admin/profile?email=${email}`, {
		method: 'GET',
		headers: getHeaders(token)
	}).then(result => {
		if (result.ok) {
			let json = result.json();
			return json;
		} else {
			throw new Error(`${process.env.REACT_APP_API_HOST}/v2/super-admin/profile?email=${email}`);
		}
	});
}

export const VerifyProducer = (token: string, gr_user: number) => {
	return fetch(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/user/verify`, {
		method: 'POST',
		headers: getHeaders(token),
		body: JSON.stringify({ gr_user })
	}).then(result => {
		if (result.ok) {
			return result.json();
		} else {
			throw new Error(`${process.env.REACT_APP_API_HOST}/v2/greenroom/admin/producer/verify`);
		}
	});
}