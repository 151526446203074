import { Cookies } from 'react-cookie';

export const COOKIE_NAMES = {
	CURRENT_USER: "currentUser",
	AUDIENCE_MEMBER: "brandlive_uid",
	TWITTER_ACCESS_TOKEN: "twitterAccessToken",
	TWITTER_ACCESS_TOKEN_SECRET: "twitterAccessTokenSecret",
	REGISTRATION_NAME_LOCATION: "registrationNameAndLocation"
};

export const DOMAINS = {
	BRANDLIVE: process.env.REACT_APP_DOMAIN
};

export function isProduction(): boolean {
	if (
		typeof window !== "undefined" &&
		window.location.href.indexOf("localhost") === -1
	) {
		return true;
	} else {
		return false;
	}
}

export function getExpirationDate(hours: number): Date {
	const now = Date.now();
	const expirationMs = 1000 * 60 * 60 * hours;
	return new Date(now + expirationMs);
}

export function setCookie(cookies: Cookies, name: string, value: string, expiresDate: Date, domain: string): void {
	if (isProduction()) {
		if (expiresDate) {
			// console.log('setting with expires date ' + expiresDate + ' to domain ' + domain);
			cookies.set(name, value,
				{ path: "/", expires: expiresDate, domain: domain }
			);
		} else {
			cookies.set(name, value, { path: "/", domain: domain });
		}
	} else {
		if (expiresDate) {
			cookies.set(name, value, { path: "/", expires: expiresDate });
		} else {
			cookies.set(name, value, { path: "/" });
		}
	}
}

export function removeCookie(cookies: Cookies, name: string, domain: string): void {
	if (isProduction()) {
		cookies.remove(name, { path: "/", domain: domain });
	} else {
		cookies.remove(name, { path: "/" });
	}
}

export function getChatCookieName(liveChat: number): string {
	return `chat[${liveChat}]`;
}

export function getImageLocation(uri: string): string | undefined {
	if (!uri) return;

	let image = "";
	if (uri.includes("assets/")) {
		image = "https://brand.live/" + uri;
	} else if (!uri.includes("http")) {
		image = "https://cdn1.brnd.live/" + uri;
	} else {
		image = uri;
	}

	return image;
}

// Opens a popup window in the center of the screen. The reason it opens a _blank window and returns the window instead of going
// straight to the destination URL is because of popup blockers. For twitter login we tried opening a popup window in the return function of the
// request-token API call but the window would get blocked because it wasn't being opened in a direct user action (button click, link click, etc.
// This is standard browser popup security)
export function popupCenter(w: number, h: number): Window | null {
	const left = (screen.width / 2) - (w / 2); // eslint-disable-line no-restricted-globals
	const top = (screen.height / 2) - (h / 2); // eslint-disable-line no-restricted-globals

	const newWindow: Window | null = window.open("", "_blank", `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`);

	// Puts focus on the newWindow
	if (newWindow && newWindow.focus) newWindow.focus();
	return newWindow;
}

export function getAudienceMemberSessionCookieName(liveChat: number): string {
	return `session_${liveChat}`;
}

export function isValidEmail(email: string): boolean {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function isValidPassword(password: string): boolean {
	const hasEightChars = () => {
		return password.length >= 8;
	};

	const hasOneLowercase = () => {
		return (/[a-z]/.test(password));
	};

	const hasOneUpperCase = () => {
		return (/[A-Z]/.test(password));
	};

	const hasOneNumber = () => {
		return (/[0-9]/.test(password));
	};

	return hasEightChars() && hasOneLowercase() && hasOneUpperCase() && hasOneNumber();
}

export function getProfileImageURL(item: any): string {
	let picture = "";
	if (item.picture) {
		picture = item.picture;
	} else if (item.image) {
		picture = item.image;
	} else if (item.profile_picture) {
		picture = item.profile_picture;
	}
	return picture;
}

export function getFullAssetURI(asset: string): string {
	if (!asset) {
		return "";
	} else if (
		asset.startsWith("http://") ||
		asset.startsWith("https://") ||
		asset.startsWith("//")
	) {
		return asset;
	} else if (asset.startsWith("/assets/")) {
		return "https://cdn.brnd.live" + asset;
	} else if (asset.startsWith("assets/")) {
		return "https://brand.live/" + asset;
	} else {
		return "https://cdn1.brnd.live/" + asset;
	}
}

function hexToArray(hex: string): number[] {
	if (hex.includes("#")) {
		hex = hex.substr(1, hex.length);
	}

	let chars = hex.split("");

	let r = chars[0] + chars[1];
	let g = chars[2] + chars[3];
	let b = chars[4] + chars[5];
	let a = 255;

	let _r = parseInt(r, 16);
	let _g = parseInt(g, 16);
	let _b = parseInt(b, 16);

	return [_r, _g, _b, a];
}

//calculates luminance https://en.wikipedia.org/wiki/Luma_(video)
function avg(pixelData: Uint8ClampedArray | number[]): number {
	//pixelData is a UInt8Array

	//loop through array
	let len = pixelData.length;
	let totLuma = 0;
	let avg = 0;
	let rgb = [];

	for (let i = 0; i < len; ++i) {
		//the color values come in as a single array, but the individual pixel values are quads
		//so arrayed like this: [r, g, b, a, r, g, b, a, r, g, b, a...]
		//we want to ignore the alpha channel, so we create a temp array to hold
		//the rgb values, then whenever this array hits the alpha number, calculate the luma of the pixel
		//data without doing anything with the alpha number (so each 4th iteration, ignore the value and calculate the luminance)
		if (((i + 1) % 4) > 0) {
			rgb[i + 1 % 4] = pixelData[i];
		} else {
			let R = rgb[1];
			let G = rgb[2];
			let B = rgb[3];

			//fast formula found here https://stackoverflow.com/questions/596216/formula-to-determine-brightness-of-rgb-color
			totLuma += (R + R + R + B + G + G + G + G) >> 3;
		}
	}

	//get average color of image
	avg = totLuma / (len / 4);
	return avg;
}

let textColor: string | null = null;

export function getContrastingColor(theme: any): Promise<string | null> {
	return new Promise((resolve, reject) => {
		//to keep this from being recalculated every state update, just do it once, the background image is static
		if (textColor) {
			return resolve(textColor);
		}

		//if the background color and header background color is not set but a background image is set
		if (!theme.background_color_override && !theme.header_background_color_override && theme.background_image) {
			try {
				//create an image from the background_image
				let img = document.createElement("img");

				//needed to prevent CORS error in admin console
				img.crossOrigin = "Anonymous";

				//when image loads
				img.onload = () => {
					//create a canvas, set to the size of the image
					let canvas = document.createElement("canvas");
					canvas.width = img.width;
					canvas.height = img.height;

					//draw the image to the canvas
					let ctx = canvas.getContext("2d");
					if (ctx) {
						ctx.drawImage(img, 0, 0, img.width, img.height);

						//we want to measure a 20x20px box 30px inset from the top right edge
						let xStart = img.width - 50;

						//gets average luminance
						let luminance = avg(ctx.getImageData(xStart, 30, 20, 20).data);

						if (luminance > 128) {
							textColor = "#4A4A4A";
						} else {
							textColor = "#ffffff";
						}

						resolve(textColor);
					} else {
						resolve(null);
					}
				};

				//load image
				img.src = theme.background_image;
			} catch (e) {
				console.error(e);
				resolve(null);
			}
		} else if (theme.background_color_override && !theme.header_background_color_override) {
			try {
				let luminance = avg(hexToArray(theme.background_color));

				if (luminance > 128) {
					textColor = "#4A4A4A";
				} else {
					textColor = "#ffffff";
				}

				resolve(textColor);
			} catch (e) {
				console.error(e);
				resolve(null);
			}
		} else {
			resolve(null);
		}
	});
}

export function urlLoads(url: string): Promise<boolean> {
	return fetch(url).then(res => {
		if (res.status === 404) {
			return false;
		}
		return true;
	}).catch(e => {
		console.error(e);
		return false;
	});
}

/**
 * 
 * @param key key name of item in array you want to be unique
 * @param arr array you want to dump duplicates from
 */
export function uniqueArray(key: string, arr: any[]): any[] {
	let map = new Map(arr.map((item: any) => [item[key], item]));
	return Array.from(map.values());
}

export function toMap(key: string, arr: any[]): Map<any, any> {
	let map = new Map(arr.map((item: any) => [item[key], item]));
	return map;
}

interface Hashmap {
	[key: string]: any;
}

export function toHashmap(key: string, arr: any[]): Hashmap {
	let hash: Hashmap = {};
	let len = arr.length;

	for (let i = 0; i < len; ++i) {
		hash[arr[i][key]] = arr[i];
	}

	return hash;
}

export function countTimer(totalSeconds: number, timeText: any) {
	++totalSeconds;
	let hour = Math.floor(totalSeconds / 3600) as any;
	let minutes = Math.floor((totalSeconds - hour * 3600) / 60) as any;
	let seconds = totalSeconds - (hour * 3600 + minutes * 60) as any;
	if (hour < 10)
		hour = "0" + hour;
	if (minutes < 10)
		minutes = "0" + minutes;
	if (seconds < 10)
		seconds = "0" + seconds;
	timeText.innerHTML = minutes + " : " + seconds;
	console.log(`test ${minutes + " : " + seconds}`)
	return totalSeconds;
}