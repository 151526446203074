import { Channel } from "../../types/types";
import { LOAD_CHANNELS, SET_SELECTED_CHANNEL, UPDATE_SELECTED_CHANNEL } from "../actions/channels";
import { handle } from 'redux-pack';

interface Action {
	type: string;
	payload: any;
}

export interface ChannelsState {
	channels: Channel[];
	channelsMap: Map<number, Channel>;
	channelsError: string;
	channelsLoading: boolean;
	selectedChannel: Channel | null;
}

const initialState: ChannelsState = {
	channels: [],
	channelsMap: new Map(),
	channelsError: "",
	channelsLoading: false,
	selectedChannel: null
}

const actions: Map<any, Function> = new Map([
	[LOAD_CHANNELS, (state: ChannelsState, action: Action): ChannelsState => handle(state, action, {
		start: state => ({
			...state,
			channelsLoading: true
		}),
		finish: state => ({
			...state,
			channelsLoading: false
		}),
		failure: state => ({
			...state,
			channelsError: "Unable to load events " + action.payload
		}),
		success: state => ({
			...state,
			channels: action.payload.channels ? action.payload.channels : [],
			channelsMap: action.payload.channels && new Map(action.payload.channels.map((channel: Channel) => [channel.channel, channel]))
		})
	})],
	[SET_SELECTED_CHANNEL, (state: ChannelsState, action: Action): ChannelsState => ({ ...state, selectedChannel: action.payload })],
	[UPDATE_SELECTED_CHANNEL, (state: ChannelsState, action: Action): ChannelsState => ({ ...state, selectedChannel: action.payload })],
]);

export function ChannelsReducer(state: ChannelsState = initialState, action: Action) {
	const newState = actions.get(action.type)?.(state, action);
	return newState || state;
}