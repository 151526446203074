const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
}

function getHeaders(token: string) {
    return {
        ...headers,
        'bl-token': token
    }
}

export const GetUploadEndpoint = async (token: string, prefix: string, filename: string, subdirectory?: string) => {
    return fetch(`${process.env.REACT_APP_API_HOST}/v2/greenroom/media/upload`, {
        method: 'POST',
        headers: getHeaders(token),
        body: JSON.stringify({
            filename,
            prefix,
            subdirectory
        })
    }).then(result => {
        if (result.ok) {
            return result.json();
        } else {
            throw new Error(`${process.env.REACT_APP_API_HOST}/v2/greenroom/media/upload didn't work`);
        }
    });
}

export const UploadToS3 = async (url: string, file: File | Blob, data: any, progress?: Function, filename?: string, returnFileHttpRequest?: Function) => {
    const formData = new FormData();
    for (let key in data) {
        formData.append(key, data[key]);
    }
    formData.append('file', file, filename);

    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        returnFileHttpRequest?.(request);
        request.open('POST', url);

        request.upload.onprogress = (e) => {
            const uploaded = e.loaded;
            progress?.(uploaded);
        }

        request.onreadystatechange = () => {
            if (request.readyState === 4) {
                resolve(true);
            }
        }

        request.send(formData);
    });
}

export const MakeItemPublic = (token: string, filename: string) => {
    return fetch(`${process.env.REACT_APP_API_HOST}/v2/greenroom/media/upload/finish`, {
        method: 'POST',
        headers: getHeaders(token),
        body: JSON.stringify({
            filename
        })
    }).then(result => {
        if (result.ok) {
            return result.ok;
        } else {
            throw new Error(`${process.env.REACT_APP_API_HOST}/v2/greenroom/media/upload/finish didn't work`);
        }
    });
}