import React, { Component } from "react";
// import LoadingSpinner from "./loading-spinner";
import { random } from "../utils/random";
// import { loadAvatar } from "./avatar";
import { addListener, clearListeners } from "./alert-service";
import * as Storage from '../utils/local-storage';

export const ALERT_TYPES = {
	WARNING: 1,
	ERROR: 2,
	NEUTRAL: 3,
	POSITIVE: 4,
	CHAT: 5
};

export default class Alerts extends Component {
	state = {
		animationStates: {},
		message: "",
		type: "positive",
		showSpinner: false,
		alerts: [] as any[]
	};

	shouldComponentUpdate(nextProps: any, nextState: any) {

		if (nextState.alerts.length !== this.state.alerts.length) {
			return true;
		}

		let len = nextState.alerts.length;
		for (let i = 0; i < len; ++i) {
			if (nextState.alerts[i].animationState !== this.state.alerts[i].animationState) {
				return true;
			}

			if (nextState.alerts[i].show !== this.state.alerts[i].show) {
				return true;
			}
		}

		return false;
	}

	componentDidMount() {
		addListener("showAlert", this.show);
		addListener("showAlertLong", this.showLong);
		addListener("hideAlert", this.hide);
		addListener("noConnectionAlert", this.noConnection);
		addListener("showAlertOpentokMuted", this.showOpentokMuted);
	}

	componentWillUnmount() {
		clearListeners();
	}

	parseType(type: any) {
		if (type) {
			if (typeof type === "string") {
				switch (type) {
					case "warning":
						return type;
					case "error":
						return type;
					case "neutral":
						return type;
					case "chat":
						return "neutral";
					default: {
						console.warn(
							"Invalid alert type, valid types = 'warning', 'error', 'neutral', 'chat', or undefined"
						);
						return "positive";
					}
				}
			} else {
				switch (type) {
					case ALERT_TYPES.WARNING:
						return "warning";
					case ALERT_TYPES.ERROR:
						return "error";
					case ALERT_TYPES.NEUTRAL:
						return "neutral";
					case ALERT_TYPES.POSITIVE:
						return "positive";
					case ALERT_TYPES.CHAT:
						return "chat";
					default:
						return "neutral";
				}
			}
		} else {
			return "positive";
		}
	}

	trigger = (alert: any) => {
		this.setState(
			{ alerts: [...this.state.alerts, alert] },
			() => {
				setTimeout(() => {
					this.setState({
						alerts: this.state.alerts.map(al => {
							if (al.id === alert.id) {
								al.animationState = "show";
							}

							return al;
						})
					}, this.forceUpdate);
				}, 50);

				if (alert.duration) {
					setTimeout(() => {
						this.hide(alert);
					}, alert.duration);
				}
			}
		);

		return alert.id;
	};

	show = ({
		message = "",
		duration = 5000,
		type = "positive",
		spinner = false,
		description = null,
		requirementsButton = false
	}) => {
		let id = random(10);
		let alert = {
			id: id,
			duration: duration,
			type: this.parseType(type),
			spinner: spinner,
			description: description,
			animationState: "hide",
			message: message,
			show: true,
			showButtons: false,
			requirementsButton: requirementsButton
		};

		return this.trigger(alert);
	};

	showLong = ({
		message = "",
		type = "positive",
		spinner = false,
		description = null,
		signOut = false
	}) => {
		let id = random(10);
		let alert = {
			id: id,
			type: this.parseType(type),
			spinner: spinner,
			description: description,
			animationState: "hide",
			message: message,
			showButtons: false,
			show: true,
			duration: 1000000,
			signOut: signOut
		};

		return this.trigger(alert);
	};

	showChat = ({ name = "", message = "", duration = 6000, image = "", user = "" }) => {
		let id = random(10);
		let alert = {
			id: id,
			message: name,
			description: message,
			duration: duration,
			type: "neutral",
			showButtons: false,
			show: true,
			image: image,
			chat: true,
			user: user
		};

		return this.trigger(alert);
	};

	showOpentokMuted = ({ name = "", message = "", description = "" }) => {
		let id = random(10);
		let alert = {
			id: id,
			message: message,
			description: description,
			duration: null,
			type: "neutral",
			show: true,
			animationState: "hide",
			opentokMuted: true,
		};

		return this.trigger(alert);
	};

	noConnection = () => {
		let id = "no connection";

		let alert = {
			id: id,
			type: "warning",
			description:
				"Could not get a connection. Please check your internet and try again.",
			animationState: "hide",
			message: "Connection Problem",
			show: true,
			duration: 1000
		};

		if (this.state.alerts.find(al => al.id === "no connection")) {
			return;
		} else {
			return this.trigger(alert);
		}
	};

	showException = () => {
		let id = "exception";

		let alert = {
			id: id,
			type: "error",
			description:
				"We're sorry, Brandlive Presenter ran into an unexpected error. An error report has been sent to our engineers.",
			animationState: "hide",
			message: "Unexpected Error",
			show: true,
			duration: 6000,
			exception: true
		};

		if (this.state.alerts.find(al => al.id === "exception")) {
			return;
		} else {
			return this.trigger(alert);
		}
	};

	hide = (alert: any) => {
		if (!alert) {
			if (this.state.alerts.length > 0) {
				alert = this.state.alerts[0];
			} else {
				return;
			}
		}

		this.setState(
			{
				alerts: this.state.alerts.map(al => {
					if (al.id === alert.id) {
						al.animationState = "hide";
					}

					return al;
				})
			},
			() => {
				this.forceUpdate();
				setTimeout(() => {
					this.setState({
						alerts: this.state.alerts.map(al => {
							if (al.id === alert.id) {
								al.show = false;
							}

							return al;
						})
					}, this.forceUpdate);
				}, 300);

				setTimeout(() => {
					this.purgeHidden();
				}, 10000);
			}
		);
	};

	purgeHidden = () => {
		this.setState({
			alerts: this.state.alerts.filter(al => al.show === true)
		}, this.forceUpdate);
	};

	showDate = () => {
		return new Date().toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "2-digit"
		});
	};

	unmuteOpentok = (alert: any) => {
		// Display the mute buttons in the thumbnails now that browser audio is unmuted
		document.documentElement.className = document.documentElement.className.replace("hide-opentok-mute-buttons", "");
		// window.OT.unblockAudio();
		this.hide(alert);
	};

	signOut = () => {
		Storage.removeStorageItem('token');
		window.location.reload();
	}

	renderAlert = (alert: any, i: any) => {
		return alert.show ? (
			<div
				key={i}
				className={["alert", alert.type, alert.animationState].join(" ")}
			>
				{!alert.image && (
					<div className="alert-icon">
						<i className={alert.chat ? "icon-chat" : "icon-alert"} />
					</div>
				)}

				{/* Opentok unmute toaster requires user to click unmute button (no X close button) */}
				{!alert.opentokMuted &&
					// eslint-disable-next-line jsx-a11y/anchor-is-valid
					<a className="clear-alert" onClick={() => this.hide(alert)}>
						<i className="icon-close-circle" />
					</a>
				}

				{/* {alert.image ? (
					<div className="alert-image">
						{loadAvatar({
							picture: alert.image,
							name: alert.message
						})}
					</div>
				) : null} */}

				<div className="alert-content">
					<span className="alert-title">{alert.message}</span>

					{(alert.description || alert.spinner) && (
						<div className="alert-description-wrap">
							{/* {alert.spinner ? (
								<div className="alert-spinner">
									<LoadingSpinner
										height={20}
										width={20}
										color={"#BABABA"}
										stroke={8}
									/>
								</div>
							) : null} */}
							{alert.description && (
								<div
									dangerouslySetInnerHTML={{ __html: alert.description }}
									className="alert-description"
								/>
							)}
						</div>
					)}

					{/* {alert.user ? (
						<div className="alert-buttons">
							<button onClick={() => this.goToReply(alert)} className="xsmall">
								Reply
							</button>
						</div>
					) : null} */}

					{alert.signOut && (
						<div className="alert-buttons">
							<button onClick={this.signOut} className="xsmall">
								Sign Out
							</button>
						</div>
					)}

					{alert.opentokMuted ? (
						<div className="alert-buttons">
							<button onClick={() => this.unmuteOpentok(alert)} id="unmute-streams-btn" className="xsmall positive">
								Unmute Streams
							</button>
						</div>
					) : null}

					{/* <label>{this.showDate()}</label> */}
				</div>
			</div>
		) : null;
	};

	render() {
		return (
			<div className="alerts-container">
				{this.state.alerts.map((alert, i) => {
					return this.renderAlert(alert, i);
				})}
			</div>
		);
	}
}
