import { combineReducers } from 'redux';
import { UserReducer } from './user';
import { UsersReducer } from './users';
import { ChannelsReducer } from './channels';

const appReducers = combineReducers({
	UserReducer,
	UsersReducer,
	ChannelsReducer
});

export default appReducers;