import { GreenroomUser } from "../../types/types";
import { CLEAR_USERS, LOAD_PRESENTERS, LOAD_PRODUCERS, SET_SELECTED_USER, SET_VERIFIED, UPDATE_SELECTED_USER } from "../actions/users";
import { handle } from 'redux-pack';

interface Action {
	type: string;
	payload: any;
}

export interface UsersState {
	users: GreenroomUser[];
	selectedUser: GreenroomUser | null;
	usersError: string;
	usersLoading: boolean;
}

const initialState: UsersState = {
	users: [],
	selectedUser: null,
	usersError: "",
	usersLoading: false
}

const actions: Map<any, Function> = new Map([
	[CLEAR_USERS, (state: UsersState, action: Action) => ({ ...state, users: [] })],
	[LOAD_PRODUCERS, (state: UsersState, action: Action): UsersState => handle(state, action, {
		start: state => ({
			...state,
			usersLoading: true
		}),
		finish: state => ({
			...state,
			usersLoading: false
		}),
		failure: state => ({
			...state,
			usersError: "Unable to load users " + action.payload
		}),
		success: state => ({
			...state,
			users: action.payload
		})
	})],
	[LOAD_PRESENTERS, (state: UsersState, action: Action): UsersState => handle(state, action, {
		start: state => ({
			...state,
			usersLoading: true
		}),
		finish: state => ({
			...state,
			usersLoading: false
		}),
		failure: state => ({
			...state,
			usersError: "Unable to load users " + action.payload
		}),
		success: state => ({
			...state,
			users: action.payload
		})
	})],
	[SET_SELECTED_USER, (state: UsersState, action: Action) => ({ ...state, selectedUser: action.payload })],
	[UPDATE_SELECTED_USER, (state: UsersState, action: Action) => ({ ...state, selectedUser: action.payload })],
	[SET_VERIFIED, (state: UsersState, action: Action): UsersState => handle(state, action, {
		failure: state => ({
			...state,
			profileError: "Unable to verify",
		}),
		success: state => ({
			...state,
			users: state.users.map((user: GreenroomUser) => {
				console.log(user.gr_user, action.payload)
				if (user.gr_user === Number(action.payload.verified)) {
					user.verified = true;
				}

				return user;
			}),
			profileError: ""
		})
	})]
]);

export function UsersReducer(state: UsersState = initialState, action: Action) {
	const newState = actions.get(action.type)?.(state, action);
	return newState || state;
}