import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { isValidEmail } from '../utils/utils';
import { signIn } from '../stores/actions/user';
import { UserState } from '../stores/reducers/user';

interface StateProps {
	UserReducer: UserState;
}

interface DispatchProps {
	submitForm: Function;
}


const mapStateToProps = (state: any, ownProps: any) => {
	return { ...state, ...ownProps }
}

const mapDispatchToProps = (dispatch: Function) => {
	//the actions you want to fire from this component
	return {
		submitForm: (email: string, password: string) => {
			dispatch(signIn(email, password));
		}
	}
}

type Props = StateProps & DispatchProps;

function Login(props: Props) {
	const {
		UserReducer: {
			signInError,
			signinLoading
		},
		submitForm
	} = props;

	const email = useRef<HTMLInputElement | null>(null);
	const password = useRef<HTMLInputElement | null>(null);
	const [localError, setLocalError] = useState('');

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLocalError('');

		if (email.current?.value && password.current?.value) {
			if (isValidEmail(email.current.value)) {
				submitForm(email.current.value, password.current.value);
			} else {
				setLocalError("Invalid email");
			}
		} else {
			setLocalError("Nothing has been entered");
		}
	}

	return (
		<div className="login-wrapper">
			<div className="container">
				<form onSubmit={handleSubmit}>
					<h1>Sign In</h1>
					<input type="email" ref={email} placeholder="Email" required />
					<input type="password" ref={password} placeholder="Password" required />
					<input className="button action" disabled={signinLoading} type="submit" value={signinLoading ? "Please Wait..." : "Sign In"} />
				</form>
			</div>
			{signInError && (
				<span className="error">{signInError}</span>
			)}
			{localError && (
				<span className="error">{localError}</span>
			)}
		</div>
	)
}

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(Login);