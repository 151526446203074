import { ReportError } from "../connection/errors";
import { isProduction } from "./utils";
import StackTrace from "stacktrace-js";

let lastError = "";
let token = "";
let shouldHandle = true;

function sendErrorReport(err: any, msg: any) {
	if (isProduction())
		ReportError(token, navigator.userAgent, err, msg).catch(e =>
			console.error(e)
		);
}

function handleException(msg: any, file: any, line: any, col: any, error: any) {
	if (!shouldHandle) throw new Error(undefined);
	StackTrace.fromError(error)
		.then(err => {
			console.error(err);

			//dont double log errors
			if (lastError === msg) return;

			sendErrorReport(err, msg);

			lastError = msg;
		})
		.catch(e => {
			console.error(e);
		});
}

export function blockErrorHandling() {
	shouldHandle = false;
}

export function setErrorToken(userToken: string) {
	token = userToken;
}

export function setupErrorHandling() {
	window.onerror = (...args) => handleException(...args);
	Object.freeze(window.onerror);
}
