const listeners = new Map();

export function addListener(name: string, func: any) {
	listeners.set(name, func);
}

export const showAlert = (options: any) => {
	const func = listeners.get("showAlert");
	return func && func(options);
};

export const showAlertLong = (options: any) => {
	const func = listeners.get("showAlertLong");
	return func && func(options);
};

export const hideAlert = (options: any) => {
	const func = listeners.get("hideAlert");
	return func && func(options);
};

export const noConnectionAlert = () => {
	const func = listeners.get("noConnectionAlert");
	return func && func();
};

export const showAlertOpentokMuted = (options: any) => {
	const func = listeners.get("showAlertOpentokMuted");
	return func && func(options);
};

export const clearListeners = () => {
	listeners.clear();
};

export const ALERT_TYPES = {
	WARNING: 1,
	ERROR: 2,
	NEUTRAL: 3,
	POSITIVE: 4,
	CHAT: 5
};

Object.freeze(showAlert);
Object.freeze(showAlertLong);
Object.freeze(showAlertOpentokMuted);
Object.freeze(hideAlert);
Object.freeze(noConnectionAlert);
Object.freeze(clearListeners);
