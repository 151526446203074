import { Action, AsyncAction, Channel } from '../../types/types';
import { LoadChannels } from '../../connection/channels';

export const LOAD_CHANNELS = "LOAD_CHANNELS";
export function loadChannels(token: string): AsyncAction {
	return {
		type: LOAD_CHANNELS,
		promise: LoadChannels(token)
	}
}

export const SET_SELECTED_CHANNEL = "SET_SELECTED_CHANNEL";
export function setSelectedChannel(client: Channel | null): Action {
	return {
		type: SET_SELECTED_CHANNEL,
		payload: client
	}
}

export const UPDATE_SELECTED_CHANNEL = "UPDATE_SELECTED_CHANNEL";
export function updateSelectedChannel(client: Channel): Action {
	return {
		type: UPDATE_SELECTED_CHANNEL,
		payload: client
	}
}
