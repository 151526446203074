import { BrandliveUser } from "../../types/types";
import * as Storage from '../../utils/local-storage';
import { CHECK_USER, SIGN_IN, SIGN_OUT } from "../actions/user";
import JwtDecode from 'jwt-decode';
import { handle } from 'redux-pack';

interface Action {
	type: string;
	payload: any;
}

export interface UserState {
	token: any;
	user: BrandliveUser | null;
	userError: boolean;
	signInError: string;
	signinLoading: boolean;
}

const initialToken = Storage.getStorageItem('admin-token');

const initialState: UserState = {
	token: null,
	user: null,
	userError: false,
	signInError: '',
	signinLoading: false
}

const actions: Map<any, Function> = new Map([
	[CHECK_USER, (state: UserState, action: Action): UserState => {
		if (initialToken) {
			const _user: BrandliveUser = JwtDecode(initialToken);
			if (_user) {
				if (_user.is_super_admin) {
					//extend token expiration by 1 hour
					Storage.setStorageItem('admin-token', initialToken, 1);
					return { ...state, token: initialToken, user: _user };
				} else {
					Storage.removeStorageItem('admin-token');
					return { ...state, userError: true };
				}
			} else {
				Storage.removeStorageItem('admin-token');
				return { ...state, userError: true };
			}
		} else {
			return state;
		}
	}],
	[SIGN_IN, (state: UserState, action: Action): UserState => handle(state, action, {
		start: state => ({
			...state,
			signinLoading: true
		}),
		finish: state => ({
			...state,
			signinLoading: false
		}),
		failure: state => ({
			...state,
			signinError: action.payload
		}),
		success: state => {
			if (!action.payload) {
				return {
					...state,
					signInError: "Email and password not found"
				}
			} else if (action.payload && action.payload.message) {
				return {
					...state,
					signInError: action.payload.message
				}
			} else if (action.payload && action.payload.error) {
				return {
					...state,
					signInError: `Looks like you've attempted to sign in with ${action.payload.error}. Please follow the "Forgot Password" link to set a password for this account.`
				}
			} else {
				Storage.setStorageItem('admin-token', action.payload.token, 1);
				let user: BrandliveUser = JwtDecode(action.payload.token);

				if (!user.is_super_admin) {
					return {
						...state,
						signInError: "You do not have access to this page. Please check your inputs and try again."
					}
				} else {
					return {
						...state,
						signInError: null,
						userError: false,
						token: action.payload.token,
						user: user
					}
				}
			}
		}
	})],
	[SIGN_OUT, (state: UserState, action: Action): UserState => {
		Storage.clearStorage();
		return {
			...state,
			token: null,
			user: null
		}
	}]
]);

export function UserReducer(state: UserState = initialState, action: Action) {
	const newState = actions.get(action.type)?.(state, action);
	return newState || state;
}